import { Service } from './Service.dto'
import { DTOClass } from './DTOClass'

export class ServicesList extends DTOClass {
    constructor ({
        services = [],
        discount = null,
        note = null
    } = {}) {
        super()
        if (Array.isArray(services)) {
            this.services = services.map((item) => {
                if (item instanceof Service) {
                    return item
                }
                return new Service(item)
            })
        } else {
            this.services = []
        }
        this.discount = discount
        this.note = note
    }

    static get namespace () {
        return 'servicesList'
    }

    get namespace () {
        return 'servicesList'
    }

    toObject () {
        return {
            services: this.services.map(item => item.toObject()),
            discount: this.discount,
            note: this.note
        }
    }

    static fromObject (dtoObject) {
        return new ServicesList(dtoObject)
    }
}
