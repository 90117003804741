import { DTOClass } from './DTOClass'
import { Reference } from './Reference.dto'

export class ReferenceList extends DTOClass {
    constructor (references = []) {
        super()
        this.references = references.map(item => new Reference(item))
    }

    static get namespace () {
        return 'referenceList'
    }

    get namespace () {
        return 'referenceList'
    }

    toObject () {
        return this.references.map(item => item.toObject())
    }

    static fromObject (dtoObject) {
        return new ReferenceList(dtoObject)
    }
}
