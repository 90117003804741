import { DTOClass } from './DTOClass'
import { Software } from './Software.dto'

export class PropertyLists extends DTOClass {
    constructor ({
        nativeSpeakerLanguages = [],
        specializations = [],
        forms = [],
        software = [],
        catTools = [],
        certifications = [],
        experienceFrom = null,
        education = null,
        educationInField = null,
        employmentInField = null,
        fullTimeJobFrom = null,
        note = null
    } = {}) {
        super()
        this.nativeSpeakerLanguages = nativeSpeakerLanguages
        this.specializations = specializations
        this.forms = forms
        this.software = software.map(item => new Software(item))
        this.catTools = catTools.map(item => new Software(item))
        this.certifications = certifications
        this.experienceFrom = experienceFrom
        this.education = education
        this.educationInField = educationInField
        this.employmentInField = employmentInField
        this.fullTimeJobFrom = fullTimeJobFrom
        this.note = note
    }

    static get namespace () {
        return 'propertyLists'
    }

    get namespace () {
        return 'propertyLists'
    }

    toObject () {
        return {
            nativeSpeakerLanguages: this.nativeSpeakerLanguages,
            specializations: this.specializations,
            forms: this.forms,
            software: this.software.map(item => item.toObject()),
            catTools: this.catTools.map(item => item.toObject()),
            certifications: this.certifications,
            experienceFrom: this.experienceFrom,
            fullTimeJobFrom: this.fullTimeJobFrom,
            education: this.education,
            educationInField: this.educationInField,
            employmentInField: this.employmentInField,
            note: this.note
        }
    }

    static fromObject (dtoObject) {
        return new PropertyLists(dtoObject)
    }
}
