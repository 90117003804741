import { DTOClass } from './DTOClass'

export class WorkHours extends DTOClass {
    constructor ({
        monday = false,
        mondayFrom = null,
        mondayTo = null,
        tuesday = false,
        tuesdayFrom = null,
        tuesdayTo = null,
        wednesday = false,
        wednesdayFrom = null,
        wednesdayTo = null,
        thursday = false,
        thursdayFrom = null,
        thursdayTo = null,
        friday = false,
        fridayFrom = null,
        fridayTo = null,
        saturday = false,
        saturdayFrom = null,
        saturdayTo = null,
        sunday = false,
        sundayFrom = null,
        sundayTo = null,
        holidays = false,
        note = null
    } = {}) {
        super()
        this.monday = monday
        this.mondayFrom = mondayFrom
        this.mondayTo = mondayTo
        this.tuesday = tuesday
        this.tuesdayFrom = tuesdayFrom
        this.tuesdayTo = tuesdayTo
        this.wednesday = wednesday
        this.wednesdayFrom = wednesdayFrom
        this.wednesdayTo = wednesdayTo
        this.thursday = thursday
        this.thursdayFrom = thursdayFrom
        this.thursdayTo = thursdayTo
        this.friday = friday
        this.fridayFrom = fridayFrom
        this.fridayTo = fridayTo
        this.saturday = saturday
        this.saturdayFrom = saturdayFrom
        this.saturdayTo = saturdayTo
        this.sunday = sunday
        this.sundayFrom = sundayFrom
        this.sundayTo = sundayTo
        this.holidays = holidays
        this.note = note
    }

    static get namespace () {
        return 'workHours'
    }

    get namespace () {
        return 'workHours'
    }

    toObject () {
        return {
            monday: this.monday,
            mondayFrom: this.mondayFrom,
            mondayTo: this.mondayTo,
            tuesday: this.tuesday,
            tuesdayFrom: this.tuesdayFrom,
            tuesdayTo: this.tuesdayTo,
            wednesday: this.wednesday,
            wednesdayFrom: this.wednesdayFrom,
            wednesdayTo: this.wednesdayTo,
            thursday: this.thursday,
            thursdayFrom: this.thursdayFrom,
            thursdayTo: this.thursdayTo,
            friday: this.friday,
            fridayFrom: this.fridayFrom,
            fridayTo: this.fridayTo,
            saturday: this.saturday,
            saturdayFrom: this.saturdayFrom,
            saturdayTo: this.saturdayTo,
            sunday: this.sunday,
            sundayFrom: this.sundayFrom,
            sundayTo: this.sundayTo,
            holidays: this.holidays,
            note: this.note
        }
    }

    static fromObject (dtoObject) {
        return new WorkHours(dtoObject)
    }
}
