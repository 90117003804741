import { DTOClass } from './DTOClass'

export class Service extends DTOClass {
    constructor ({
        fromLanguages = [],
        toLanguages = [],
        services = [],
        includeInverse = false
    } = {}) {
        super()
        this.fromLanguages = fromLanguages || []
        this.toLanguages = toLanguages || []
        this.services = services || []
        this.includeInverse = !!includeInverse
    }

    static get namespace () {
        return 'service'
    }

    get namespace () {
        return 'service'
    }

    toObject () {
        return {
            fromLanguages: this.fromLanguages,
            toLanguages: this.toLanguages,
            services: this.services,
            includeInverse: this.includeInverse
        }
    }

    static fromObject (dtoObject) {
        return new Service(dtoObject)
    }
}
