import { Document } from './Document.dto'
import { DTOClass } from './DTOClass'

export class DocumentsList extends DTOClass {
    constructor ({
        documents = [],
        note = null
    } = {}) {
        super()
        if (Array.isArray(documents)) {
            this.documents = documents.map((item) => {
                if (item instanceof Document) {
                    return item
                }
                return new Document(item)
            })
        } else {
            this.documents = []
        }
        this.note = note
    }

    static get namespace () {
        return 'documentsList'
    }

    get namespace () {
        return 'documentsList'
    }

    toObject () {
        return {
            documents: this.documents.map(item => item.toObject()),
            note: this.note
        }
    }

    static fromObject (dtoObject) {
        return new DocumentsList(dtoObject)
    }
}
