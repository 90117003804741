import { DTOClass } from './DTOClass'

export class BasicData extends DTOClass {
    constructor ({
        type = null,
        companyName = null,
        firstName = null,
        lastName = null,
        prenominal = null,
        postnominal = null,
        dateOfBirth = null,
        salutation = null,
        communicationLanguage = null,
        phoneNumber = null,
        email = null,
        website = null,
        skype = null,
        note = null,
        legalId = null
    } = {}) {
        super()
        this.type = type
        this.companyName = companyName
        this.firstName = firstName
        this.lastName = lastName
        this.prenominal = prenominal
        this.postnominal = postnominal
        this.dateOfBirth = dateOfBirth
        this.salutation = salutation
        this.communicationLanguage = communicationLanguage
        this.phoneNumber = phoneNumber
        this.email = email
        this.website = website
        this.skype = skype
        this.note = note
        this.legalId = legalId
    }

    static get namespace () {
        return 'basicData'
    }

    get namespace () {
        return 'basicData'
    }

    toObject () {
        return {
            type: this.type,
            companyName: this.companyName,
            firstName: this.firstName,
            lastName: this.lastName,
            prenominal: this.prenominal,
            postnominal: this.postnominal,
            dateOfBirth: this.dateOfBirth,
            salutation: this.salutation,
            communicationLanguage: this.communicationLanguage,
            phoneNumber: this.phoneNumber,
            email: this.email,
            website: this.website,
            skype: this.skype,
            note: this.note,
            legalId: this.legalId
        }
    }

    static fromObject (dtoObject) {
        return new BasicData(dtoObject)
    }
}
