import { DTOClass } from './DTOClass'

export class ContactPerson extends DTOClass {
    constructor ({
        firstName = null,
        lastName = null,
        prenominal = null,
        postnominal = null,
        dateOfBirth = null,
        salutation = null,
        role = null,
        position = null,
        department = null,
        communicationLanguage = null,
        phoneNumber = null,
        email = null,
        website = null,
        skype = null,
        note = null
    } = {}) {
        super()
        this.firstName = firstName
        this.lastName = lastName
        this.prenominal = prenominal
        this.postnominal = postnominal
        this.dateOfBirth = dateOfBirth
        this.salutation = salutation
        this.role = role
        this.position = position
        this.department = department
        this.communicationLanguage = communicationLanguage
        this.phoneNumber = phoneNumber
        this.email = email
        this.website = website
        this.skype = skype
        this.note = note
    }

    static get namespace () {
        return 'contactPerson'
    }

    get namespace () {
        return 'contactPerson'
    }

    toObject () {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            prenominal: this.prenominal,
            postnominal: this.postnominal,
            dateOfBirth: this.dateOfBirth,
            salutation: this.salutation,
            role: this.role,
            position: this.position,
            department: this.department,
            communicationLanguage: this.communicationLanguage,
            phoneNumber: this.phoneNumber,
            email: this.email,
            website: this.website,
            skype: this.skype,
            note: this.note
        }
    }

    static fromObject (dtoObject) {
        return new ContactPerson(dtoObject)
    }
}
