import { DTOClass } from './DTOClass'

export class Reference extends DTOClass {
    constructor ({
        fromLanguage = [],
        toLanguage = [],
        services = [],
        specializations = [],
        forms = [],
        amount = null,
        amountUnit = null,
        note = null,
        tempId = null
    } = {}) {
        super()
        this.fromLanguage = fromLanguage
        this.toLanguage = toLanguage
        this.services = services
        this.specializations = specializations
        this.amount = amount
        this.amountUnit = amountUnit
        this.note = note
        this.tempId = tempId
        this.forms = forms
    }

    static get namespace () {
        return 'reference'
    }

    get namespace () {
        return 'reference'
    }

    toObject () {
        return {
            fromLanguage: this.fromLanguage,
            toLanguage: this.toLanguage,
            services: this.services,
            specializations: this.specializations,
            forms: this.forms,
            amount: this.amount,
            amountUnit: this.amountUnit,
            note: this.note,
            tempId: this.tempId
        }
    }

    static fromObject (dtoObject) {
        return new Reference(dtoObject)
    }
}
