import { DTOClass } from './DTOClass'

export class Document extends DTOClass {
    constructor ({
        id = null,
        name = null,
        sanitizedName = null,
        size = null,
        mimeType = null,
        createdAt = null,
        persisted = null,
        type = null,
        tempId = null,
        customName = null
    } = {}) {
        super()
        this.id = id
        this.name = name
        this.sanitizedName = sanitizedName
        this.size = size
        this.mimeType = mimeType
        this.createdAt = createdAt
        this.persisted = persisted
        this.type = type
        this.tempId = tempId
        this.customName = customName
    }

    static get namespace () {
        return 'document'
    }

    get namespace () {
        return 'document'
    }

    toObject () {
        return {
            id: this.id,
            name: this.name,
            sanitizedName: this.sanitizedName,
            size: this.size,
            mimeType: this.mimeType,
            createdAt: this.createdAt,
            persisted: this.persisted,
            type: this.type,
            tempId: this.tempId,
            customName: this.customName
        }
    }

    static fromObject (dtoObject) {
        return new Document(dtoObject)
    }
}
