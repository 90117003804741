import { DTOClass } from './DTOClass'

export class Address extends DTOClass {
    constructor ({
        street = null,
        houseNumber = null,
        city = null,
        zip = null,
        region = null,
        country = null,
        timeZone = null,
        note = null
    } = {}) {
        super()
        this.street = street
        this.houseNumber = houseNumber
        this.city = city
        this.zip = zip
        this.region = region
        this.country = country
        this.timeZone = timeZone
        this.note = note
    }

    static get namespace () {
        return 'address'
    }

    get namespace () {
        return 'address'
    }

    toObject () {
        return {
            street: this.street,
            houseNumber: this.houseNumber,
            city: this.city,
            zip: this.zip,
            region: this.region,
            country: this.country,
            timeZone: this.timeZone,
            note: this.note
        }
    }

    static fromObject (dtoObject) {
        return new Address(dtoObject)
    }
}
