import { DTOClass } from './DTOClass'

export class Software extends DTOClass {
    constructor ({
        application = null,
        version = null
    } = {}) {
        super()
        this.application = application
        this.version = version
    }

    static get namespace () {
        return 'software'
    }

    get namespace () {
        return 'software'
    }

    toObject () {
        return {
            application: this.application,
            version: this.version
        }
    }

    static fromObject (dtoObject) {
        return new Software(dtoObject)
    }
}
